$(document).ready(function(){
    if (document.getElementsByClassName('js-itemsCategoryCarousel')) {
        $('.itemsCarouselContainer').slick({
            slidesToShow: 6,
            infinite: true,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<span class="slick-prev"></span>',
            nextArrow: '<span class="slick-next"></span>',
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        autoplay: true
                    }
                }
            ]
        });
        $('.itemsCarouselContainer').removeClass('itemsCarousel-loader');
    }
});
